<template>

  <div class="item content">
    <h3>Резисторы поверхностного монтажа</h3>
    <p>Резистор постоянный непроволочный Р1-112 ДАРГ.434110.004 ТУ</p>
    <img src="@/assets/img/chip2.jpg">
    <div class="prim">
    <p class="prodprim">Примечания:</p>
    <p class="prodprim">1. Помеченное * — кроме резисторов Р1-112 — 0,032.</p>
    <p class="prodprim">2. Приведенные характеристики относятся к ЧИП-резисторам серии Р1 — 112 общего назначения (для нужд народного хозяйства) в соответствии с ТУ 6030-002-12471727-2003.</p>
    <p class="prodprim">3. Во всех габаритах производится перемычка сопротивлением менее 0,05 Ом.</p>
    <p class="prodprim">4. Маркируются все габариты кроме Р1-112-0,032 и Р1-112-0,062.</p>
    <p class="prodprim">5. Упаковка продукции — россыпью, в ленту.</p>
    <p class="prodprim">6. Возможны варианты исполнения по дополнительным требованиям Заказчика.</p>
  </div>
    <p class="prodlist">1. Диапазон номинальных мощностей от 0,032 Вт  до 5,0 Вт.</p>
    <p class="prodlist">2. Диапазон номинальных сопротивлений от 0,05 Ом до 1 ГОм.</p>
    <p class="prodlist">3. Класс точности:</p>
    <p class="prodl"> ±1%, ±2% в диапазоне сопротивлений от 10 Ом до 1 МОм по ряду Е 96 и Е 48 соответственно;</p>
    <p class="prodl">±5%,  ±10%   в диапазоне  сопротивлений от 1 Ом до 22 МОм по ряду Е 24;</p>
    <p class="prodl"> ±10%    в диапазоне  сопротивлений от 0,05 Ом до 1 ГОм по ряду Е 24.</p>
    <p class="prodlist">4. Температурный коэффициент сопротивления:</p>
    <p class="prodl">  (К) ± 100´10-6 1/°С   в диапазоне сопротивлений от 100 Ом до 100 кОм;</p>
    <p class="prodl">  (Л) ± 250´10-6 1/°С   в диапазоне сопротивлений от 1 Ом до 91 Ом и  от 110 кОм до 22 МОм;</p>
    <p class="prodl">(М) ± 500´10-6 1/°С   в диапазоне сопротивлений от 0,05 Ом до 0,91 Ом;</p>
    <p class="prodl">(Н) ± 2000´10-6 1/°С   в диапазоне сопротивлений от 24 МОм до 1 ГОм.</p>
    <p class="prodlist">5. ЭДС шумов гр.А  до 1 МОм, выше 1 МОм ЭДС шумов не нормируется.</p>
    <p class="prodlist">6. Изменение сопротивления после воздействия спецфакторов не более ±3%.</p>
    <p class="prodlist">7. Изменение сопротивления после 1000 часов эксплуатации при Р=Рном и Т=85°С не более ±3%</p>
    <p class="prodlist">8. Минимальная наработка 50000 часов при Р = Рном   и  Т = +85°С.</p>
    <p class="prodlist">9. Облегченный режим:</p>
    <p class="prodl">- наработка 150000 часов при Р = 0,6Рном   и  Т = +70°С;</p>
    <p class="prodl">- наработка 200000 часов при  Р = 0,4Рном   и  Т = +40°С.</p>
    <p class="prodlist">10. Резисторы Р1-112 — 0,125 ÷ 5,0  маркируются.</p>
    <p class="prodlist">11. Гарантийный срок 25 лет</p>


  </div>
</template>

<style scoped>
.content {
grid-area: content;
padding: 10px 20px;
text-shadow: 1px 1px 1px #000000;
line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
/*font: 900 20px Arial;*/
border-left: 1px solid rgb(255,255,255,.4);
}
h3{
text-align: center;
}
img{
  width: 100%;
  height: auto;
  max-width: 700px;
  margin-bottom: 10px;
}
.prodlist{
margin: auto;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (26 - 18) + 18px);
  font-size: calc( (100vw - 480px)/(1280 - 480) * (18 - 12) + 12px);
}
.prodl{
  margin-left:1rem;
  margin-top: auto;
  margin-bottom: auto;
  /*text-indent: 2%;*/
  line-height: calc( (100vw - 480px)/(1280 - 480) * (26 - 18) + 18px);
  font-size: calc( (100vw - 480px)/(1280 - 480) * (18 - 12) + 12px);
}
.prim{
margin-bottom: 8px;
  border: 1px solid rgb(255,255,255,.4);
  padding: 5px 5px;
}
.prodprim{
  margin: auto;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (20 - 12) + 12px);
  font-size: calc( (100vw - 480px)/(1280 - 480) * (14 - 10) + 10px);
}
</style>
